/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { uniqueId } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Form, Button, Icon, Tab, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import Tree, { SHOW_ALL } from 'rc-tree-select';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import { createMedia, createPlace, fetchCategoriesTree, updateMedia, updatePlace } from '../../actions';
import {
    displayFieldsMap, getAllCategories, getAllTags, getAllCities,
    validateTextInputField, displayNotification, getPlaceDetails, getMedia, buildPlaceObject,
    openingHoursCorrectFormatToSend, markerCoordinatesCorrectFormat, getPlaces, linkPlacesCorrectFormatToSend,
    getUserDetails,
} from '../../utils';
import { EditorArea, MediaUploader, MediaUploaderList, RowActionButton, MapElement } from '../elements';
import withModal from '../hocs/with_modal';
import { COUNTRIES, PHOTO_TYPES } from '../../constants';
import { WEEK_DAYS } from '../../constants/week_days';

const weekDaysOptions = Object.keys(WEEK_DAYS).map(k => ({
    key: k,
    text: WEEK_DAYS[k],
    value: k,
}));

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    categories: [],
    categoryOptions: [],
    tags: [],
    cityOptions: [],
    activeSocialField: null,
    editingCoverPhoto: true,
    initialCoverMediaObject: null,
    mapRefs: [],
    searchPlaces: [],
    place: {
        // media elements
        cover: null,
        media: [],
        listCovers: [],
        // simple data elements
        name: null,
        title: null,
        citySequence: null,
        mainCategorySequence: null,
        description: null,
        manualOpeningHours: false,
        alwaysOpen: false,
        permanentlyClosed: false,
        openingHoursNotes: null,
        openingHours: [],
        openingHoursNoInfo: false,
        tagsSequences: [],
        placeCategoriesSequences: [],
        placeAddress: null,
        phone: null,
        email: null,
        website: null,
        wikipedia: null,
        facebookPlaceId: null,
        facebookPlaceUrl: null,
        bookingPlaceId: null,
        bookingPlaceUrl: null,
        foursquarePlaceId: null,
        foursquarePlaceUrl: null,
        googlePlaceId: null,
        googlePlaceUrl: null,
        instagramHashTags: null,
        instagramPlaceId: null,
        instagramPlaceUrl: null,
        instagramLocationId: null,
        instagramLocationUrl: null,
        tripAdvisorPlaceId: null,
        tripAdvisorPlaceUrl: null,
        twitterHashTags: null,
        twitterPlaceId: null,
        twitterPlaceUrl: null,
        yelpPlaceId: null,
        yelpPlaceUrl: null,
        zomatoPlaceId: null,
        zomatoPlaceUrl: null,
        latitude: null,
        longitude: null,
        markerCoordinates: [],
        linkPlaces: [{}],
        comingSoon: false,
    },
};

class CreatePlaceModal extends Component {
    state = INITIAL_STATE;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { categoryOptions } = this.props;
        const { categoryOptions: prevCategoryOptions } = prevProps;
        const { categoryOptions: stateCategoryOptions } = this.state;
        if (categoryOptions && categoryOptions !== prevCategoryOptions && stateCategoryOptions.length === 0) {
            this.mapNodes();
        }
    }

    mapNodes = () => {
        const { categoryOptions: categoryTree } = this.props;
        const categoryOptions = [];
        categoryTree.forEach(node => categoryOptions.push(this.mapNode(node)));
        this.setState({ categoryOptions });
    };

    mapNode = (node) => {
        const children = [];
        if (node.children.length > 0) node.children.forEach(child => children.push(this.mapNode(child)));
        return { disabled: false, key: node.sequence, value: node.sequence, title: node.name, isLeaf: node.childrenNumber === 0, children };
    };

    onMapMounted = index => (ref) => {
        const { mapRefs } = this.state;
        mapRefs[index] = ref;
        this.setState({ mapRefs });
    }

    onMarkerDragEnd = index => ({ latLng }) => {
        const newMarkerCoordinates = [...this.state.place.markerCoordinates];
        newMarkerCoordinates[index] = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };

        this.setState({
            place: {
                ...this.state.place,
                markerCoordinates: newMarkerCoordinates,
            },
        });
    }

    onSaveCoverClick = (type, data, uploaderLocked) => {
        switch (type) {
            case PHOTO_TYPES.COVER:
                if (this.validateCover(type, data, uploaderLocked)) {
                    const { token } = this.props;
                    const { place } = this.state;
                    if (uploaderLocked) {
                        this.props.updateMedia(token, data, place.cover.mediaSequence, () => {
                            this.setState({ editingCoverPhoto: false });
                        });
                    } else {
                        this.props.createMedia(token, data, (coverSequence) => {
                            this.setState({
                                place: {
                                    ...place,
                                    cover: {
                                        mediaSequence: coverSequence,
                                    },
                                },
                                editingCoverPhoto: false,
                            });
                        });
                    }
                }
                break;
            default:
        }
    };

    onEditCoverClick = type => this.setState({ [type]: true });

    onRemoveCoverClick = (type) => {
        switch (type) {
            case 'editingCoverPhoto':
                this.setState({ place: { ...this.state.place, coverSequence: null } });
                break;
            default:
        }
        this.setState({ [type]: true });
    };

    onListCoverMediaChange = (mediaList) => {
        const { place: { listCovers } } = this.state;
        const mediaListObjects = mediaList.map((mediaSequence) => {
            const existingMediaObject = listCovers.find(lc => lc.mediaSequence === mediaSequence);
            if (existingMediaObject) return existingMediaObject;
            return { mediaSequence };
        });
        this.setState({
            place: {
                ...this.state.place,
                listCovers: [...mediaListObjects],
            },
        });
    };

    onMediaChange = (mediaList) => {
        const { place: { media } } = this.state;
        const mediaListObjects = mediaList.map((mediaSequence) => {
            const existingMediaObject = media.find(m => m.mediaSequence === mediaSequence);
            if (existingMediaObject) return existingMediaObject;
            return { mediaSequence };
        });
        this.setState({
            place: {
                ...this.state.place,
                media: [...mediaListObjects],
            },
        });
    };

    onSocialFieldBlur = () => this.setState({ activeSocialField: null });

    getTrigger = () => {
        if (this.props.place) {
            return <RowActionButton text="Edit" icon="pencil" color="yellow" onClick={this.handleOpen} />;
        }

        return (
            <Button
                icon
                primary
                style={{ marginBottom: 10 }}
                onClick={this.handleOpen}
                floated="right"
                labelPosition="left"
                size="small">
                <Icon name="info circle" /> Create Place
            </Button>
        );
    };

    getCitySequenceBasedOnPlace = (place) => {
        let city = null;
        let country = null;
        let key = null;
        let citySearch = place.address_components.find(addr => addr.types.includes('locality'));
        if (!citySearch) citySearch = place.address_components.find(addr => addr.types.includes('administrative_area_level_1'));
        if (citySearch) city = citySearch.short_name;
        const countrySearch = place.address_components.find(addr => addr.types.includes('country'));
        if (countrySearch) country = countrySearch.short_name;
        const searchString = `${city} - ${COUNTRIES[country]}`;
        const existingCity = this.state.cityOptions.find(cityOption => cityOption.text.toLowerCase().includes(searchString.toLowerCase()));
        if (existingCity) ({ key } = existingCity);
        return key;
    };

    getPlaceDetailsWrapper = (placeId) => {
        return new Promise((resolve) => {
            try {
                const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
                placesService.getDetails({ fields: ['opening_hours'], placeId }, resolve);
            } catch (error) {
                resolve(null);
            }
        });
    }

    handleOpen = () => {
        const { token } = this.props;
        this.props.fetchCategoriesTree(token);
        axios.all([
            getAllCategories(token),
            getAllTags(token),
            getAllCities(token),
        ]).then(axios.spread((categories, tags, cities) => {
            const parsedCategories = categories.map((category) => {
                return { key: category.sequence, text: category.name, value: category.sequence };
            });
            const parsedTags = tags.map((tag) => {
                return { key: tag.sequence, text: tag.name, value: tag.sequence };
            });
            const parsedCities = cities.map((city) => {
                return { key: city.sequence, text: `${city.name} - ${COUNTRIES[city.countryCode]}`, value: city.sequence };
            });

            if (this.props.place) {
                Promise.resolve(getPlaceDetails(token, this.props.place.sequence))
                    .then(({
                        place: placeDetails,
                        cover: placeCover,
                        listCovers: placeListCovers,
                        media: placeMedia,
                        categories: placeCategories,
                        tags: placeTags,
                        openingHours,
                        parents,
                        children }) => {
                        if (placeCover) {
                            Promise.resolve(getMedia(token, placeCover.mediaSequence))
                                .then(({ data: coverData }) => {
                                    const coverDataAux = coverData;
                                    Promise.resolve(getUserDetails(token, coverData.userSequence))
                                        .then(({ data: userData }) => {
                                            coverDataAux.userName = userData.name;
                                            this.setState({
                                                initialCoverMediaObject: coverDataAux.other ? null : coverDataAux,
                                                editingCoverPhoto: false,
                                            });
                                        });
                                }).catch(() => {});
                        }

                        const parsedPlace = buildPlaceObject(
                            placeDetails,
                            placeCover,
                            placeMedia,
                            placeListCovers,
                            placeCategories,
                            placeTags,
                            openingHours,
                            parents,
                            children,
                        );


                        // build searchPlaces
                        const newSearchPlaces = [];
                        parsedPlace.linkPlaces.forEach((linkPlace, index) => {
                            newSearchPlaces[index] = [{
                                key: linkPlace.linkPlaceSequence,
                                text: linkPlace.text,
                                value: linkPlace.linkPlaceSequence,
                            }];
                        });


                        this.setState({
                            place: {
                                ...parsedPlace,
                            },
                            categories: parsedCategories,
                            tags: parsedTags,
                            cityOptions: parsedCities,
                            modalOpen: true,
                            searchPlaces: newSearchPlaces,
                        }, () => {
                            setTimeout(() => {
                                this.state.mapRefs.forEach((mapRef, index) => {
                                    if (mapRef) {
                                        mapRef.panTo(this.state.place.markerCoordinates[index]);
                                    }
                                });
                            }, 100);

                            if (!this.state.place.manualOpeningHours) {
                                this.handlePlaceOpeningHours(this.state.place.googlePlaceId);
                            }
                        });
                    });
            } else {
                this.setState({
                    categories: parsedCategories,
                    tags: parsedTags,
                    cityOptions: parsedCities,
                    modalOpen: true,
                });
            }
        }));
    };

    handleClose = () => this.setState({ ...INITIAL_STATE });

    handleSubmit = () => {
        if (this.validateForm()) {
            const placeWithCorrectOpeningHours = { ...this.state.place };
            const _openingHoursCorrectFormatToSend = openingHoursCorrectFormatToSend(this.state.place.openingHours);
            placeWithCorrectOpeningHours.openingHours = _openingHoursCorrectFormatToSend;

            const placeWithCorrectMarkerFormat = { ...placeWithCorrectOpeningHours };
            placeWithCorrectMarkerFormat.markerCoordinates = markerCoordinatesCorrectFormat(placeWithCorrectOpeningHours.markerCoordinates);

            const placeWithCorrectPlaceLinks = { ...placeWithCorrectMarkerFormat };
            const { parents, children } = linkPlacesCorrectFormatToSend(placeWithCorrectPlaceLinks.linkPlaces);
            placeWithCorrectPlaceLinks.parents = parents;
            placeWithCorrectPlaceLinks.children = children;

            if (this.props.place) this.props.updatePlace(this.props.token, placeWithCorrectPlaceLinks, this.handleClose);
            else this.props.createPlace(this.props.token, placeWithCorrectPlaceLinks, this.handleClose);
        }
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            activeSocialField: name,
            place: {
                ...this.state.place,
                [name]: value,
            },
        });
    };

    handleComingSoon = () => {
        const { place } = this.state;

        this.setState({
            place: {
                ...place,
                comingSoon: !place.comingSoon,
            },
        });
    };

    placesSearchQuery = [];
    handleLinkAnotherPlaceChange = index => (e, { name, value }) => {
        const newLinkPlaces = [...this.state.place.linkPlaces];
        const newPlacesSearchQuery = [...this.placesSearchQuery];

        if (name === 'linkPlaceSequence') {
            const findText = this.state.searchPlaces[index].find(place => (place.value === value));
            newLinkPlaces[index] = {
                ...newLinkPlaces[index],
                [name]: value,
                text: findText.text,
            };

            newPlacesSearchQuery[index] = findText.text;
        } else {
            newLinkPlaces[index] = { ...newLinkPlaces[index], [name]: value };
        }


        this.placesSearchQuery = newPlacesSearchQuery;
        this.setState({
            place: {
                ...this.state.place,
                linkPlaces: newLinkPlaces,
            },
        });
    }

    handleAddLinkPlace = () => {
        const newLinkPlaces = [...this.state.place.linkPlaces];
        newLinkPlaces.push({});
        this.setState({
            place: {
                ...this.state.place,
                linkPlaces: newLinkPlaces,
            },
        });
    }

    searchChange = (index, searchQuery) => {
        const { token } = this.props;

        const newPlacesSearchQuery = [...this.placesSearchQuery];
        newPlacesSearchQuery[index] = searchQuery;
        this.placesSearchQuery = newPlacesSearchQuery;

        Promise.resolve(getPlaces(token, searchQuery)).then((results) => {
            if (!Array.isArray(results)) return;

            //  too late
            if (this.placesSearchQuery[index] !== searchQuery) return;

            const parsedPlaces = results.map((place) => {
                return { key: place.sequence, text: `${place.title}, ${place.cityName}`, value: place.sequence };
            });

            const newSearchPlaces = [...this.state.searchPlaces];
            newSearchPlaces[index] = parsedPlaces;

            this.setState({
                searchPlaces: newSearchPlaces,
            });
        });
    };

    validateCover = (type, data, uploaderLocked) => {
        let errors = {};
        Object.keys(data).forEach((key) => {
            switch (key) {
                case 'mediaRaw':
                    if (!uploaderLocked) {
                        errors[key] = validateTextInputField(data[key], key, displayFieldsMap[key], 'CREATE_CITY_FORM');
                        if (!errors[key]) delete errors[key];
                    }
                    break;
                default:
                    console.log(`Something went wrong evaluating ${key}.`);
            }
            if (key === 'mediaMeta') {
                Object.keys(data[key]).forEach((metaKey) => {
                    switch (metaKey) {
                        case 'captionDate':
                        case 'userObject':
                            errors[metaKey] = validateTextInputField(data[key][metaKey], metaKey, displayFieldsMap[metaKey], 'CREATE_CITY_FORM');
                            if (!errors[metaKey]) delete errors[metaKey];
                            break;
                        default:
                            console.log(`Something went wrong evaluating ${metaKey}.`);
                    }
                });
            }
        });

        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) {
            errors = null;
            this.activeMediaSubmission.delete(type);
        } else this.activeMediaSubmission.add(type);
        this.setState({ formErrors: errors });
        return errors === null;
    };

    validateForm = () => {
        let errors = {};
        Object.keys(this.state.place).forEach((field) => {
            switch (field) {
                case 'name':
                case 'cover':
                case 'media':
                case 'listCovers':
                case 'description':
                case 'citySequence':
                case 'mainCategorySequence':
                    errors[field] = validateTextInputField(this.state.place[field], field, displayFieldsMap[field], 'CREATE_PLACE_FORM');
                    if (!errors[field]) delete errors[field];
                    break;
                default:
                    console.log(`Something went wrong evaluating ${field}.`);
            }
        });

        Object.keys(errors).forEach((key) => {
            switch (key) {
                case 'cover':
                    this.activeMediaSubmission.add(PHOTO_TYPES.COVER);
                    errors.mediaRaw = { message: errors.cover };
                    break;
                case 'media':
                case 'listCovers':
                    errors.mediaRaw = { message: errors.cover };
                    break;
                default:
            }
        });

        if (
            !errors.name &&
            !errors.description &&
            !errors.citySequence &&
            !errors.mainCategorySequence &&
            ['cover', 'media', 'listCovers'].some(e => Object.keys(errors).includes(e))
        ) {
            displayNotification({ type: 'error', message: 'You haven\'t uploaded the mandatory media or you have not saved it yet.' });
        }

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    };

    handleChangeDescription = (descriptionHtml) => {
        this.setState({
            place: {
                ...this.state.place,
                description: descriptionHtml,
            },
        });
    };

    handleChangeHourNotes = (descriptionHtml) => {
        this.setState({
            place: {
                ...this.state.place,
                openingHoursNotes: descriptionHtml,
            },
        });
    };

    handleChangeContact = (contactHtml) => {
        this.setState({
            place: {
                ...this.state.place,
                contact: contactHtml,
            },
        });
    };

    handleChangeCity = (e, el) => {
        this.setState({
            ...this.state,
            place: {
                ...this.state.place,
                citySequence: el.value,
            },
        });
    };

    handleChangeManualOpeningHours = () => {
        this.setState({
            place: {
                ...this.state.place,
                manualOpeningHours: !this.state.place.manualOpeningHours,
            },
        }, () => {
            if (!this.state.place.manualOpeningHours) {
                this.handlePlaceOpeningHours(this.state.place.googlePlaceId); // reset openingHours
            }
        });
    }

    handleChangeMainCategory = (value) => {
        this.setState({
            place: {
                ...this.state.place,
                mainCategorySequence: value,
            },
        });
    };

    handleCategoryChange = (data) => {
        this.setState({
            place: {
                ...this.state.place,
                placeCategoriesSequences: data.map(d => d.value),
            },
        });
    };

    handlePlaceSelected = (place) => {
        this.handlePlaceOpeningHours(place.place_id);
        const citySequence = this.getCitySequenceBasedOnPlace(place);

        this.setState({
            ...this.state,
            place: {
                ...this.state.place,
                name: place.name,
                title: place.name,
                citySequence,
                googlePlaceId: place.place_id,
                googlePlaceUrl: place.url,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                manualOpeningHours: false,
                alwaysOpen: false,
                openingHoursNoInfo: false,
                markerCoordinates: [{
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }],
            },
        }, () => {
            setTimeout(() => {
                this.state.mapRefs.forEach((mapRef, index) => {
                    if (mapRef) {
                        mapRef.panTo(this.state.place.markerCoordinates[index]);
                    }
                });
            }, 100);
        });
    };

    handlePlaceOpeningHours = async (placeId) => {
        const { opening_hours } = await this.getPlaceDetailsWrapper(placeId);
        if (!opening_hours) {
            this.setState({
                place: {
                    ...this.state.place,
                    openingHours: [],
                },
            });
            return;
        }

        const { periods } = opening_hours;
        if (periods.length === 0) return;

        const newOpenedMap = {};
        let alwaysOpen = false;

        periods.forEach((period) => {
            const { open, close } = period;
            const { day } = open;

            const nextDay = current => ((current + 1) > 6 ? 0 : (current + 1));
            //  always open
            if (close === undefined) {
                alwaysOpen = true;
            } else if (open.day === close.day || nextDay(open.day) === close.day) {
                const openingHours = `${open.time.slice(0, 2)}:${open.time.slice(2, 4)}`;

                const closingHours = `${close.time.slice(0, 2)}:${close.time.slice(2, 4)}`;

                const hashHours = `${openingHours}-${closingHours}`;

                if (!newOpenedMap[hashHours]) {
                    newOpenedMap[hashHours] = {
                        days: [],
                        open: openingHours,
                        close: closingHours,
                    };
                }

                newOpenedMap[hashHours].days.push(weekDaysOptions[day].value);
            } else {
                let currentDay = day;

                while (currentDay !== close.day) {
                    let openTime;
                    let closeTime;
                    if (nextDay(currentDay) === close.day) {
                        openTime = '0000';
                        closeTime = close.time;
                    } else if (currentDay === open.day) {
                        openTime = open.time;
                        closeTime = '0000';
                    } else {
                        openTime = '0000';
                        closeTime = '0000';
                    }

                    const openingHours = `${openTime.slice(0, 2)}:${openTime.slice(2, 4)}`;

                    const closingHours = `${closeTime.slice(0, 2)}:${closeTime.slice(2, 4)}`;

                    const hashHours = `${openingHours}-${closingHours}`;

                    if (!newOpenedMap[hashHours]) {
                        newOpenedMap[hashHours] = {
                            days: [],
                            open: openingHours,
                            close: closingHours,
                        };
                    }

                    newOpenedMap[hashHours].days.push(weekDaysOptions[currentDay].value);

                    currentDay = nextDay(currentDay);
                }
            }
        });


        const newOpenedHours = [];
        Object.keys(newOpenedMap).forEach((k) => {
            newOpenedHours.push(newOpenedMap[k]);
        });
        newOpenedHours.sort(({ days }, { days: otherDays }) => (days.length > otherDays.length ? -1 : 1));


        // normalize

        const normalize = [];
        newOpenedHours.forEach((period) => {
            period.days.forEach((day) => {
                normalize.push({
                    open: period.open,
                    close: period.close,
                    day,
                });
            });
        });


        this.setState({
            place: {
                ...this.state.place,
                openingHours: normalize,
                alwaysOpen,
                openingHoursNoInfo: false,
            },
        });
    }

    handleOpenHoursChange = (m, name, index) => {
        const { openingHours } = this.state.place;
        const newOpenedHours = [...openingHours];

        if (!m) { // handle clear time picker
            newOpenedHours[index] = {
                ...newOpenedHours[index],
                [name]: null,
            };
        } else {
            const parsedValue = m.format('HH:mm');
            newOpenedHours[index] = {
                ...newOpenedHours[index],
                [name]: parsedValue,
            };
        }

        this.setState({
            place: {
                ...this.state.place,
                openingHours: newOpenedHours,
            },
        });
    }

    handleOpenHoursDaysChange = (e, index) => {
        const { openingHours } = this.state.place;
        const action = e[1];
        const { value = [] } = action;

        const newOpenedHours = [...openingHours];
        newOpenedHours[index] = {
            ...newOpenedHours[index],
            day: value,
        };
        this.setState({
            place: {
                ...this.state.place,
                openingHours: newOpenedHours,
            },
        });
    }

    handleDeletePeriod = (index) => {
        const { openingHours } = this.state.place;
        const newOpenedHours = [...openingHours];

        newOpenedHours.splice(index, 1);

        this.setState({
            place: {
                ...this.state.place,
                openingHours: newOpenedHours,
            },
        });
    }

    handleOpen24h = (index) => {
        const { openingHours } = this.state.place;
        const newOpenedHours = [...openingHours];

        newOpenedHours[index].open = '00:00';
        newOpenedHours[index].close = '00:00';

        this.setState({
            place: {
                ...this.state.place,
                openingHours: newOpenedHours,
            },
        });
    }

    handleAlwaysOpenChange = () => {
        this.setState({
            place: {
                ...this.state.place,
                alwaysOpen: !this.state.place.alwaysOpen,
                permanentlyClosed: false,
                openingHoursNoInfo: false,
                openingHours: [],
            },
        });
    }

    handlePermanentlyClosedChange = () => {
        const { place } = this.state;
        this.setState({
            place: {
                ...place,
                permanentlyClosed: !place.permanentlyClosed,
                openingHoursNoInfo: false,
                openingHours: [],
                alwaysOpen: false,
            },
        });
    };

    handleNoInfoChange = () => {
        this.setState({
            place: {
                ...this.state.place,
                openingHoursNoInfo: !this.state.place.openingHoursNoInfo,
                alwaysOpen: false,
                openingHours: [],
            },
        });
    }

    handleDeleteLinkPlace = (index) => {
        const newLinkPlaces = [...this.state.place.linkPlaces];
        newLinkPlaces.splice(index, 1);

        const newSearchPlaces = [...this.state.searchPlaces];
        newSearchPlaces.splice(index, 1);

        const newPlacesSearchQuery = [...this.placesSearchQuery];
        newPlacesSearchQuery.splice(index, 1);

        if (newLinkPlaces.length === 0) {
            newLinkPlaces.push({});
            newSearchPlaces.push([]);
        }

        this.placesSearchQuery = newPlacesSearchQuery;
        this.setState({
            place: {
                ...this.state.place,
                linkPlaces: newLinkPlaces,
            },
            searchPlaces: newSearchPlaces,
        });
    }

    activeMediaSubmission = new Set();

    renderOpenedHour = (hourDays, i) => {
        const { open, close, day } = hourDays;
        const preview = !this.state.place.manualOpeningHours;
        const open24 = (open === close) && open === '00:00';

        return (
            <Grid.Row>
                <Grid.Column width={6}>
                    <Form.Dropdown
                        fluid
                        selection
                        disabled={preview}
                        options={weekDaysOptions}
                        value={day}
                        placeholder="Work hours"
                        onChange={(...e) => this.handleOpenHoursDaysChange(e, i)}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Checkbox
                        label="open 24h"
                        disabled={preview}
                        onChange={() => this.handleOpen24h(i)}
                        checked={open24}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <TimePicker
                        allowEmpty={false}
                        disabled={preview}
                        showSecond={false}
                        value={open && moment(open, 'HH:mm')}
                        onChange={m => this.handleOpenHoursChange(m, 'open', i)}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <TimePicker
                        allowEmpty={false}
                        disabled={preview}
                        showSecond={false}
                        value={close && moment(close, 'HH:mm')}
                        onChange={m => this.handleOpenHoursChange(m, 'close', i)}
                    />
                </Grid.Column>
                <Grid.Column width={1}>
                    <Button
                        icon
                        disabled={preview}
                        onClick={() => this.handleDeletePeriod(i)}
                    >
                        <Icon
                            name="remove"
                        />
                    </Button>
                </Grid.Column>
            </Grid.Row>
        );
    }

    renderOpenedHours = () => {
        const { openingHours, alwaysOpen, openingHoursNoInfo, permanentlyClosed } = this.state.place;
        const preview = !this.state.place.manualOpeningHours;

        return (
            <Grid.Column>
                <Grid container>
                    <Checkbox
                        label="Always Open"
                        style={{ margin: '25px 0 25px 0' }}
                        checked={alwaysOpen}
                        disabled={preview}
                        onChange={this.handleAlwaysOpenChange}
                    />
                    <Checkbox
                        label="No Info"
                        style={{ margin: '25px 0 25px 0' }}
                        checked={openingHoursNoInfo}
                        disabled={preview}
                        onChange={this.handleNoInfoChange}
                    />
                    <Checkbox
                        data-testid="permanentlyClosedCheckbox"
                        label="Permanently Closed"
                        style={{ margin: '25px 0 25px 0' }}
                        checked={permanentlyClosed}
                        name="permanentlyClosed"
                        disabled={preview}
                        onChange={this.handlePermanentlyClosedChange}
                    />
                    {openingHours.map(this.renderOpenedHour)}
                </Grid>
                {
                    alwaysOpen ||
                    <Grid.Row>
                        <Grid width={1} container >
                            <Button
                                style={{ marginTop: '25px' }}
                                disabled={preview}
                                onClick={() => this.setState(state => ({
                                    place: {
                                        ...state.place,
                                        openingHours: [
                                            ...state.place.openingHours,
                                            { day: '', open: '', close: '',
                                            }],
                                    },
                                }
                                ))}>+
                            </Button>
                        </Grid>
                    </Grid.Row>
                }
            </Grid.Column>
        );
    }

    renderSocialMediaTabsContent = () => {
        const { place, activeSocialField } = this.state;
        const {
            facebookPlaceId, facebookPlaceUrl,
            bookingPlaceId, bookingPlaceUrl,
            foursquarePlaceId, foursquarePlaceUrl,
            instagramHashTags, instagramPlaceId, instagramPlaceUrl,
            instagramLocationId, instagramLocationUrl,
            tripAdvisorPlaceId, tripAdvisorPlaceUrl,
            twitterHashTags, twitterPlaceId, twitterPlaceUrl,
            yelpPlaceId, yelpPlaceUrl,
            zomatoPlaceId, zomatoPlaceUrl,
            googlePlaceId, googlePlaceUrl,
        } = place;

        return [
            {
                menuItem: 'Facebook',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Facebook Venue Id"
                                name="facebookPlaceId"
                                focus={activeSocialField === 'facebookPlaceId'}
                                autoFocus={activeSocialField === 'facebookPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={facebookPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Facebook Venue Page"
                                name="facebookPlaceUrl"
                                focus={activeSocialField === 'facebookPlaceUrl'}
                                autoFocus={activeSocialField === 'facebookPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={facebookPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Booking',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Booking Venue Id"
                                name="bookingPlaceId"
                                focus={activeSocialField === 'bookingPlaceId'}
                                autoFocus={activeSocialField === 'bookingPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={bookingPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Booking Venue Page"
                                name="bookingPlaceUrl"
                                focus={activeSocialField === 'bookingPlaceUrl'}
                                autoFocus={activeSocialField === 'bookingPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={bookingPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Foursquare',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Foursquare Venue Id"
                                name="foursquarePlaceId"
                                focus={activeSocialField === 'foursquarePlaceId'}
                                autoFocus={activeSocialField === 'foursquarePlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={foursquarePlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Foursquare Venue Page"
                                name="foursquarePlaceUrl"
                                focus={activeSocialField === 'foursquarePlaceUrl'}
                                autoFocus={activeSocialField === 'foursquarePlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={foursquarePlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Instagram',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Instagram Hashtags (comma separated, ex: place, placealternative)"
                                name="instagramHashTags"
                                value={instagramHashTags || ''}
                                focus={activeSocialField === 'instagramHashTags'}
                                autoFocus={activeSocialField === 'instagramHashTags'}
                                onBlur={this.onSocialFieldBlur}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Instagram Place ID"
                                name="instagramPlaceId"
                                focus={activeSocialField === 'instagramPlaceId'}
                                autoFocus={activeSocialField === 'instagramPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={instagramPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Instagram Place Page"
                                name="instagramPlaceUrl"
                                focus={activeSocialField === 'instagramPlaceUrl'}
                                autoFocus={activeSocialField === 'instagramPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={instagramPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Instagram Location ID"
                                name="instagramLocationId"
                                focus={activeSocialField === 'instagramLocationId'}
                                autoFocus={activeSocialField === 'instagramLocationId'}
                                onBlur={this.onSocialFieldBlur}
                                value={instagramLocationId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Instagram Location Page"
                                name="instagramLocationUrl"
                                focus={activeSocialField === 'instagramLocationUrl'}
                                autoFocus={activeSocialField === 'instagramLocationUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={instagramLocationUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'TripAdvisor',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="TripAdvisor Place ID"
                                name="tripAdvisorPlaceId"
                                focus={activeSocialField === 'tripAdvisorPlaceId'}
                                autoFocus={activeSocialField === 'tripAdvisorPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={tripAdvisorPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="TripAdvisor Business Page"
                                name="tripAdvisorPlaceUrl"
                                focus={activeSocialField === 'tripAdvisorPlaceUrl'}
                                autoFocus={activeSocialField === 'tripAdvisorPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={tripAdvisorPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Twitter',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Twitter Hashtags (comma separated, ex: place, placealternative)"
                                name="twitterHashTags"
                                focus={activeSocialField === 'twitterHashTags'}
                                autoFocus={activeSocialField === 'twitterHashTags'}
                                onBlur={this.onSocialFieldBlur}
                                value={twitterHashTags || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Twitter Place ID"
                                name="twitterPlaceId"
                                focus={activeSocialField === 'twitterPlaceId'}
                                autoFocus={activeSocialField === 'twitterPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={twitterPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Twitter Place Page"
                                name="twitterPlaceUrl"
                                focus={activeSocialField === 'twitterPlaceUrl'}
                                autoFocus={activeSocialField === 'twitterPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={twitterPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Yelp',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Yelp Business ID"
                                name="yelpPlaceId"
                                focus={activeSocialField === 'yelpPlaceId'}
                                autoFocus={activeSocialField === 'yelpPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={yelpPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Yelp Business Page"
                                name="yelpPlaceUrl"
                                focus={activeSocialField === 'yelpPlaceUrl'}
                                autoFocus={activeSocialField === 'yelpPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={yelpPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Zomato',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Zomato Place ID"
                                name="zomatoPlaceId"
                                focus={activeSocialField === 'zomatoPlaceId'}
                                autoFocus={activeSocialField === 'zomatoPlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={zomatoPlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Zomato Business Page"
                                name="zomatoPlaceUrl"
                                focus={activeSocialField === 'zomatoPlaceUrl'}
                                autoFocus={activeSocialField === 'zomatoPlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={zomatoPlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
            {
                menuItem: 'Google',
                pane:
                    (
                        <Tab.Pane key={uniqueId()}>
                            <Form.Input
                                fluid
                                label="Google Place ID"
                                name="googlePlaceId"
                                focus={activeSocialField === 'googlePlaceId'}
                                autoFocus={activeSocialField === 'googlePlaceId'}
                                onBlur={this.onSocialFieldBlur}
                                value={googlePlaceId || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                            <Form.Input
                                fluid
                                label="Google Business Page"
                                name="googlePlaceUrl"
                                focus={activeSocialField === 'googlePlaceUrl'}
                                autoFocus={activeSocialField === 'googlePlaceUrl'}
                                onBlur={this.onSocialFieldBlur}
                                value={googlePlaceUrl || ''}
                                onChange={this.handleChange}
                                className="create-user-modal__social-media__input"
                            />
                        </Tab.Pane>
                    ),
            },
        ];
    };

    renderCoverPhotoUploader = () => {
        const { token, place } = this.props;
        const { formErrors, editingCoverPhoto, initialCoverMediaObject } = this.state;
        const isEditing = !!place;

        return (
            <MediaUploader
                mandatory
                token={token}
                initialData={(isEditing && initialCoverMediaObject) || null}
                acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4']}
                isEditing={editingCoverPhoto}
                formErrors={this.activeMediaSubmission.has(PHOTO_TYPES.COVER) ? formErrors : null}
                onSavePress={(data, uploaderLocked) => this.onSaveCoverClick(PHOTO_TYPES.COVER, data, uploaderLocked)}
                onEditMediaClick={() => this.onEditCoverClick('editingCoverPhoto')}
                onRemoveMediaClick={() => this.onRemoveCoverClick('editingCoverPhoto')}
                saveButtonLabel="Save cover media"
                title="Cover Media"
            />
        );
    };

    renderListCoverPhotoGrid = () => {
        const { token } = this.props;
        const { formErrors, place: { listCovers } } = this.state;
        return (
            <MediaUploaderList
                limit={10}
                token={token}
                hasError={formErrors && !!formErrors.listCovers}
                initialData={listCovers}
                uploaderTitle="List Cover Media"
                addMediaTitle="Add list cover media"
                validationForm="CREATE_PLACE_FORM"
                onMediaListChange={this.onListCoverMediaChange}
                emptyMediaMessage="No list cover media added yet (at least one is mandatory)."
                uploaderSaveButtonLabel="Save list cover media"
            />
        );
    };

    renderMediaPhotoGrid = () => {
        const { token } = this.props;
        const { formErrors, place: { media } } = this.state;
        return (
            <MediaUploaderList
                token={token}
                hasError={formErrors && !!formErrors.media}
                initialData={media}
                uploaderTitle="Media"
                addMediaTitle="Add media"
                validationForm="CREATE_PLACE_FORM"
                onMediaListChange={this.onMediaChange}
                emptyMediaMessage="No media added yet (at least one is mandatory)."
                uploaderSaveButtonLabel="Save media"
            />
        );
    };

    renderMaps = (map, index) => {
        return (
            <div style={{ marginTop: '12px' }}>
                {
                    index !== 0
                    &&
                    <div className="field">
                        <label>Choose another location</label>
                    </div>
                }
                {
                    index !== 0
                    &&
                    <Button
                        onClick={this.handleRemoveMap(index)}
                        floated="right"
                    >
                        -
                    </Button>
                }

                <MapElement
                    isMarkerShown
                    markerPosition={this.state.place.markerCoordinates[index]}
                    onMapMounted={this.onMapMounted(index)}
                    onMarkerDragEnd={this.onMarkerDragEnd(index)}
                />
            </div>

        );
    }

    renderLinkAnotherPlace = (p, index) => {
        const choices = [
            { key: 0, text: 'Parent', value: 'parent' },
            { key: 1, text: 'Child', value: 'child' },
        ];

        return (
            <Grid.Row>
                <Grid.Column width={10}>
                    <div className="field">
                        <Form.Dropdown
                            label="Link another place / urban area"
                            placeholder="Select an option"
                            fluid
                            search
                            selection
                            name="linkPlaceSequence"
                            value={this.state.place.linkPlaces[index].linkPlaceSequence}
                            onChange={this.handleLinkAnotherPlaceChange(index)}
                            onSearchChange={(e, { searchQuery }) => { this.searchChange(index, searchQuery); }}
                            options={this.state.searchPlaces[index]}
                        />
                    </div>
                </Grid.Column>
                <Grid.Column width={4}>
                    &nbsp;
                    <div className="field">
                        <Form.Dropdown
                            label=""
                            placeholder=""
                            fluid
                            selection
                            value={this.state.place.linkPlaces[index].linkPlaceRelation}
                            name="linkPlaceRelation"
                            onChange={this.handleLinkAnotherPlaceChange(index)}
                            options={choices}
                        />
                    </div>

                </Grid.Column>
                <Grid.Column width={1}>
                    &nbsp;
                    <Button
                        icon
                        onClick={() => this.handleDeleteLinkPlace(index)}
                    >
                        -
                    </Button>
                </Grid.Column>
            </Grid.Row>
        );
    }

    handleAddMap = () => {
        const newMarkerCoordinates = [...this.state.place.markerCoordinates];


        const lat = this.state.place.latitude;
        const lng = this.state.place.longitude;

        newMarkerCoordinates.push({ lat, lng });

        this.setState({
            place: {
                ...this.state.place,
                markerCoordinates: newMarkerCoordinates,
            },
        }, () => {
            setTimeout(() => {
                this.state.mapRefs.forEach((mapRef, index) => {
                    if (mapRef) {
                        mapRef.panTo(this.state.place.markerCoordinates[index]);
                    }
                });
            }, 100);
        });
    }

    handleRemoveMap = index => () => {
        const newMarkerCoordinates = [...this.state.place.markerCoordinates];
        newMarkerCoordinates.splice(index, 1);

        this.setState({
            place: {
                ...this.state.place,
                markerCoordinates: newMarkerCoordinates,
            },
        });
    }

    render() {
        const {
            modalOpen, place,
            tags, cityOptions, formErrors,
            categoryOptions, activeSocialField,
        } = this.state;
        const {
            name, citySequence, mainCategorySequence, description,
            tagsSequences, placeCategoriesSequences, title,
            placeAddress, phone, email, website, wikipedia,
            openingHoursNotes, comingSoon,
        } = place;
        const isEditing = !!this.props.place;

        if (!modalOpen) return this.getTrigger();

        return (
            <Modal
                closeIcon
                className="create-place-modal"
                data-testid="create-place-modal"
                onClose={this.handleClose}
                onUnmount={this.props.fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="large"
                trigger={this.getTrigger()}>
                <Modal.Header>{isEditing ? `Edit Place - ${this.props.place.name}` : 'Create Place'}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Input
                                    fluid
                                    name="place"
                                    label={`Place ${(formErrors && formErrors.name && formErrors.name.message) || ''}`}
                                    error={formErrors && !!formErrors.name}
                                    onChange={e => console.log(e.target.value)}
                                    input={
                                        <Autocomplete
                                            className="google-autocomplete"
                                            defaultValue={name || ''}
                                            onPlaceSelected={this.handlePlaceSelected}
                                            autoComplete="new-password"
                                            fields={['address_components', 'geometry.location', 'place_id', 'formatted_address', 'name']}
                                            name={`place${Date.now()}`}
                                            types={['establishment']}
                                        />
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Input
                                    fluid
                                    name="title"
                                    label="Place Title"
                                    autoComplete="new-password"
                                    placeholder="Enter place title"
                                    value={title || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                            <Checkbox
                                label="Coming Soon"
                                name="comingSoon"
                                className="coming-soon-checkbox"
                                checked={comingSoon}
                                onChange={this.handleComingSoon}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="field">
                                    <label>Choose the location</label>
                                </div>
                                {this.state.place.markerCoordinates.map(this.renderMaps)}
                                <Button
                                    onClick={this.handleAddMap}
                                    style={{ marginTop: '12px' }}
                                >+
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="field">
                                    <label>Hours</label>
                                </div>
                                <label style={{ marginRight: '9px' }}>auto</label>

                                <Checkbox
                                    label="manual"
                                    toggle
                                    checked={this.state.place.manualOpeningHours}
                                    onChange={this.handleChangeManualOpeningHours}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {this.renderOpenedHours()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16} className="field">
                                <div className="field">
                                    <label>Notes/Exceptions</label>
                                    <EditorArea
                                        onChange={this.handleChangeHourNotes}
                                        initialContent={openingHoursNotes}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Dropdown
                                    fluid
                                    search
                                    selection
                                    value={citySequence}
                                    label={`Where ? ${(formErrors && formErrors.citySequence && formErrors.citySequence.message) || ''}`}
                                    error={formErrors && !!formErrors.citySequence}
                                    placeholder="Select an option"
                                    onChange={this.handleChangeCity}
                                    options={cityOptions}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label>
                                        {`Main Category ${(formErrors && formErrors.mainCategorySequence && formErrors.mainCategorySequence.message) || ''}`}
                                    </label>
                                    <Tree
                                        showSearch={false}
                                        className={`tree-selector${formErrors && !!formErrors.mainCategorySequence ? ' error' : ''}`}
                                        treeData={categoryOptions}
                                        placeholder="Select an option"
                                        onChange={this.handleChangeMainCategory}
                                        defaultValue={mainCategorySequence ? [mainCategorySequence] : []}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 1500 }}
                                        treeDefaultExpandedKeys={[String(mainCategorySequence)]}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <div className="field">
                                    <label>Categories</label>
                                    <Tree
                                        treeCheckable
                                        treeCheckStrictly
                                        className="tree-selector"
                                        treeData={categoryOptions}
                                        placeholder="Choose categories"
                                        showCheckedStrategy={SHOW_ALL}
                                        onChange={this.handleCategoryChange}
                                        defaultValue={placeCategoriesSequences && placeCategoriesSequences.map(p => ({ value: p }))}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 1500 }}
                                        treeDefaultExpandedKeys={placeCategoriesSequences && placeCategoriesSequences.map(p => String(p))}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Dropdown
                                    label="Tags"
                                    placeholder="Select an option"
                                    fluid
                                    search
                                    multiple
                                    selection
                                    name="tagsSequences"
                                    value={tagsSequences}
                                    onChange={this.handleChange}
                                    options={tags}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Grid>
                                    {this.state.place.linkPlaces.map(this.renderLinkAnotherPlace)}
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button
                                                onClick={this.handleAddLinkPlace}
                                            >+
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8} />
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className={`field ${formErrors && !!formErrors.description ? 'error' : ''}`}>
                                    <label>{`Description ${(formErrors && formErrors.description && formErrors.description.message) || ''}`}</label>
                                    <EditorArea
                                        hasError={formErrors && !!formErrors.description}
                                        onChange={this.handleChangeDescription}
                                        initialContent={description}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column with={16}>
                                <Form.Input
                                    fluid
                                    label="Address"
                                    name="placeAddress"
                                    focus={activeSocialField === 'placeAddress'}
                                    autoFocus={activeSocialField === 'placeAddress'}
                                    onBlur={this.onSocialFieldBlur}
                                    value={placeAddress || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column with={16}>
                                <Form.Input
                                    fluid
                                    label="Phone"
                                    name="phone"
                                    focus={activeSocialField === 'phone'}
                                    autoFocus={activeSocialField === 'phone'}
                                    onBlur={this.onSocialFieldBlur}
                                    value={phone || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column with={16}>
                                <Form.Input
                                    fluid
                                    label="Email"
                                    name="email"
                                    focus={activeSocialField === 'email'}
                                    autoFocus={activeSocialField === 'email'}
                                    onBlur={this.onSocialFieldBlur}
                                    value={email || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column with={16}>
                                <Form.Input
                                    fluid
                                    label="Website"
                                    name="website"
                                    focus={activeSocialField === 'website'}
                                    autoFocus={activeSocialField === 'website'}
                                    onBlur={this.onSocialFieldBlur}
                                    value={website || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column with={16}>
                                <Form.Input
                                    fluid
                                    label="Wikipedia"
                                    name="wikipedia"
                                    focus={activeSocialField === 'wikipedia'}
                                    autoFocus={activeSocialField === 'wikipedia'}
                                    onBlur={this.onSocialFieldBlur}
                                    value={wikipedia || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {this.renderCoverPhotoUploader()}
                        {this.renderListCoverPhotoGrid()}
                        {this.renderMediaPhotoGrid()}
                        <Grid.Column width={16}>
                            <Tab
                                panes={this.renderSocialMediaTabsContent()}
                                renderActiveOnly={false}
                            />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon
                        color="green"
                        labelPosition="right"
                        onClick={this.handleSubmit}
                    >
                        Save <Icon name="save" />
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

CreatePlaceModal.propTypes = {
    token: PropTypes.string,
    place: PropTypes.object,
    createPlace: PropTypes.func,
    updatePlace: PropTypes.func,
    createMedia: PropTypes.func,
    updateMedia: PropTypes.func,
    categoryOptions: PropTypes.array,
    fetchCategoriesTree: PropTypes.func,
};

CreatePlaceModal.defaultProps = {
    token: null,
    place: null,
    createPlace: () => { },
    updatePlace: () => { },
    createMedia: () => { },
    updateMedia: () => { },
    categoryOptions: null,
    fetchCategoriesTree: () => { },
};

const mapStateToProps = ({ auth, categoriesTree }) => {
    const { token } = auth;
    const { categoriesTree: categoryOptions } = categoriesTree;
    return { token, categoryOptions };
};

export default connect(mapStateToProps, {
    createPlace,
    updatePlace,
    createMedia,
    updateMedia,
    fetchCategoriesTree,
})(withModal(CreatePlaceModal));
