/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    CITIES_REQUEST, CITIES_RECEIVED, CITIES_REQUEST_ERROR,
    CITIES_CREATE_CITY_REQUEST, CITIES_CREATE_CITY_REQUEST_ERROR,
    CITIES_DELETE_CITY_REQUEST, CITIES_DELETE_CITY_REQUEST_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST CITIES
const requestCities = () => ({
    type: CITIES_REQUEST,
});

const receiveCities = (cities, maxResults, selectedPage, totalResults) => ({
    type: CITIES_RECEIVED,
    payload: {
        cities,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestCitiesError = () => ({
    type: CITIES_REQUEST_ERROR,
});

// CREATE CITY
const requestCitiesCreateCity = () => ({
    type: CITIES_CREATE_CITY_REQUEST,
});

const requestCitiesCreateCityError = () => ({
    type: CITIES_CREATE_CITY_REQUEST_ERROR,
});

// DELETE CITIES
const requestCitiesDeleteCity = () => ({
    type: CITIES_DELETE_CITY_REQUEST,
});

const requestCitiesDeleteCityError = () => ({
    type: CITIES_DELETE_CITY_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchCities = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestCities());
        try {
            let url = `${API_URL}/city`;

            let filtersNum = 0;

            if (searchObj.countrySearch && searchObj.countrySearch.length > 0) {
                url = url.concat(`?countryCode=${searchObj.countrySearch}`);
                filtersNum++;
            }

            if (searchObj.citySearch && searchObj.citySearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}city=${searchObj.citySearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}sort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveCities(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCitiesError);
            }
        }
    };
};

export const createCity = (token, city, marker, modalClose) => {
    return async (dispatch) => {
        dispatch(requestCitiesCreateCity());
        try {
            const parsedCity = {
                name: city.name,
                countryCode: city.countryCode,
                googleLocationId: city.googleLocationId,
                googlePlaceUrl: city.googlePlaceUrl,
                latitude: city.latitude,
                longitude: city.longitude,
                landingSpotLatitude: (marker && marker.lat) || null,
                landingSpotLongitude: (marker && marker.lng) || null,
                coverSequence: city.coverSequence || null,
                exploredPhotoSequence: city.exploredPhotoSequence || null,
                emergencyNumber: city.emergencyNumber || null,
                policeDepartment: city.policeDepartment || null,
                fireDepartment: city.fireDepartment || null,
                tourismPhoneNumber: city.tourismPhoneNumber || null,
                tourismEmail: city.tourismEmail || null,
                taxis: city.taxis,
                cityInformation: city.cityInformation,
                geographicalArea: city.geographicalArea || [],
                comingSoon: city.comingSoon,
            };

            city.transportsMapMediaSequence.forEach((transportMap) => {
                parsedCity[`${transportMap.name.toLowerCase()}Map${transportMap.pdf ? 'Pdf' : 'Media'}Sequence`] = transportMap.media;
            });

            await axios.post(`${API_URL}/city`, parsedCity, getRequestConfig(token, null, false));

            dispatch(fetchCities(token));

            displayNotification({ type: 'success', message: 'City created!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCitiesCreateCityError);
            }
        }
    };
};

export const updateCity = (token, city, marker, modalClose) => {
    return async (dispatch) => {
        dispatch(requestCitiesCreateCity());
        try {
            const parsedCity = {
                name: city.name,
                countryCode: city.countryCode,
                googleLocationId: city.googleLocationId,
                googlePlaceUrl: city.googlePlaceUrl,
                latitude: city.latitude,
                longitude: city.longitude,
                landingSpotLatitude: (marker && marker.lat) || null,
                landingSpotLongitude: (marker && marker.lng) || null,
                coverSequence: city.coverSequence || null,
                exploredPhotoSequence: city.exploredPhotoSequence || null,
                emergencyNumber: city.emergencyNumber || null,
                policeDepartment: city.policeDepartment || null,
                fireDepartment: city.fireDepartment || null,
                tourismPhoneNumber: city.tourismPhoneNumber || null,
                tourismEmail: city.tourismEmail || null,
                taxis: city.taxis,
                cityInformation: city.cityInformation,
                geographicalArea: city.geographicalArea || [],
                comingSoon: city.comingSoon,
            };

            city.transportsMapMediaSequence.forEach((transportMap) => {
                parsedCity[`${transportMap.name.toLowerCase()}Map${transportMap.pdf ? 'Pdf' : 'Media'}Sequence`] = transportMap.media;
            });

            await axios.put(`${API_URL}/city/${city.sequence}`, parsedCity, getRequestConfig(token, null, false));

            dispatch(fetchCities(token));

            displayNotification({ type: 'success', message: 'City updated!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCitiesCreateCityError);
            }
        }
    };
};


export const deleteCity = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestCitiesDeleteCity());
            await axios.delete(`${API_URL}/city/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchCities(token, selectedPage, filtersObj));
            displayNotification({ type: 'success', message: 'City deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCitiesDeleteCityError);
            }
        }
    };
};
