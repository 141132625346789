/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Button, Icon, Form, Dropdown, Header, Checkbox } from 'semantic-ui-react';
import Autocomplete from 'react-google-autocomplete';
import PropTypes from 'prop-types';

import {
    createCity,
    updateCity,
    createMedia,
    updateMedia,
} from '../../actions';
import {
    displayFieldsMap,
    displayNotification,
    getCountriesList,
    getMedia,
    validateTextInputField,
} from '../../utils';
import { RowActionButton, MapElement, MediaUploader, PolygonMapElement, EditorArea } from '../elements';
import withModal from '../hocs/with_modal';
import { PHOTO_TYPES } from '../../constants';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    marker: null,
    mapRef: null,
    country: null,
    editingCoverPhoto: true,
    editingTransportPhotos: [true],
    editingExploredPhoto: true,
    initialCoverMediaObject: null,
    initialExploredMediaObject: null,
    initialTransportMediaObject: [null],
    city: {
        name: null,
        latitude: null,
        longitude: null,
        countryCode: null,
        googlePlaceUrl: null,
        googleLocationId: null,
        coverSequence: null,
        exploredPhotoSequence: null,
        transportsMapMediaSequence: [{
            name: '',
            media: null,
            pdf: false,
            key: Math.random().toString().substr(2, 8),
        }],
        emergencyNumber: null,
        policeDepartment: null,
        fireDepartment: null,
        taxis: null,
        tourismPhoneNumber: null,
        tourismEmail: null,
        cityInformation: null,
        geographicalArea: [],
        comingSoon: false,
    },
};

class CreateCityModal extends Component {
  state = INITIAL_STATE;

  componentDidUpdate(prevProps, prevState, snapshot) {
      const { city } = this.props;
      const { modalOpen } = this.state;
      const isEditing = !!city;
      if (isEditing && modalOpen) {
          if (city) {
              const {
                  landingSpotLatitude,
                  landingSpotLongitude,
                  latitude,
                  longitude,
              } = city;
              if (
                  landingSpotLatitude &&
          landingSpotLongitude &&
          this.state.mapRef &&
          !this.state.marker
              ) {
                  setTimeout(
                      () =>
                          this.state.mapRef.panTo({
                              lat: landingSpotLatitude,
                              lng: landingSpotLongitude,
                          }),
                      100,
                  );
                  this.setState({
                      marker: { lat: landingSpotLatitude, lng: landingSpotLongitude },
                  });
              } else if (
                  latitude &&
          longitude &&
          this.state.mapRef &&
          !this.state.marker
              ) {
                  setTimeout(
                      () => this.state.mapRef.panTo({ lat: latitude, lng: longitude }),
                      100,
                  );
                  this.setState({ marker: { lat: latitude, lng: longitude } });
              }
          }
      }
  }

  onMapMounted = ref => this.setState({ mapRef: ref });

  onMarkerDragEnd = ({ latLng }) =>
      this.setState({ marker: { lat: latLng.lat(), lng: latLng.lng() } });

  onCountryChange = (e, { value }) => this.setState({ country: value });

  onSavePhotoClick = (type, data, uploaderLocked, transportIndex) => {
      const { city, editingTransportPhotos } = this.state;
      const currentTransport = city.transportsMapMediaSequence[transportIndex];
      switch (type) {
          case PHOTO_TYPES.COVER:
              if (this.validateMedia(type, data, uploaderLocked)) {
                  if (uploaderLocked) {
                      this.props.updateMedia(
                          this.props.token,
                          data,
                          this.state.city.coverSequence,
                          () => {
                              this.setState({ editingCoverPhoto: false });
                          },
                      );
                  } else {
                      this.props.createMedia(this.props.token, data, (coverSequence) => {
                          this.setState({
                              city: {
                                  ...this.state.city,
                                  coverSequence,
                              },
                              editingCoverPhoto: false,
                          });
                      });
                  }
              }
              break;
          case PHOTO_TYPES.EXPLORED:
              if (this.validateMedia(type, data, uploaderLocked)) {
                  if (uploaderLocked) {
                      this.props.updateMedia(
                          this.props.token,
                          data,
                          this.state.city.exploredPhotoSequence,
                          () => {
                              this.setState({ editingExploredPhoto: false });
                          },
                      );
                  } else {
                      this.props.createMedia(
                          this.props.token,
                          data,
                          (exploredPhotoSequence) => {
                              this.setState({
                                  city: {
                                      ...this.state.city,
                                      exploredPhotoSequence,
                                  },
                                  editingExploredPhoto: false,
                              });
                          },
                      );
                  }
              }
              break;
          case PHOTO_TYPES.TRANSPORTS:
              if (this.validateMedia(type, data, uploaderLocked)) {
                  if (uploaderLocked) {
                      this.props.updateMedia(
                          this.props.token,
                          data,
                          this.state.city.subwayMapPdfSequence || this.state.city.subwayMapMediaSequence,
                          () => {
                              editingTransportPhotos[transportIndex] = false;
                              this.setState({ editingTransportPhotos });
                          },
                      );
                  } else {
                      this.props.createMedia(
                          this.props.token,
                          data,
                          (subwayMapMediaSequence) => {
                              currentTransport.media = subwayMapMediaSequence;
                              editingTransportPhotos[transportIndex] = false;
                              if (data.mediaRaw.type === 'application/pdf') {
                                  currentTransport.pdf = true;
                                  this.setState({
                                      city,
                                      editingTransportPhotos,
                                  });
                              } else {
                                  currentTransport.pdf = false;
                                  this.setState({
                                      city,
                                      editingTransportPhotos,
                                  });
                              }
                          },
                      );
                  }
              }
              break;
          default:
      }
  };

  onEditMediaClick = (type, index) => {
      const { editingTransportPhotos } = this.state;
      if (type === 'editingTransportPhotos') {
          editingTransportPhotos[index] = true;
          this.setState({ editingTransportPhotos });
      } else {
          this.setState({ [type]: true });
      }
  }

  onAddTransportMedia = () => {
      const { city, editingTransportPhotos } = this.state;

      this.setState({
          city: {
              ...city,
              transportsMapMediaSequence: [...city.transportsMapMediaSequence, {
                  name: '',
                  media: null,
                  pdf: false,
                  key: Math.random().toString().substr(2, 8),
              }],
          },
          editingTransportPhotos: [...editingTransportPhotos, true],
      });
  }

  onChangeTransportMedia = (index, name, media, pdf, key) => {
      const { city } = this.state;

      const newTransportsMapMediaSequence = [...city.transportsMapMediaSequence];

      newTransportsMapMediaSequence[index] = { name, media, pdf, key };

      this.setState({
          city: {
              ...city,
              transportsMapMediaSequence: newTransportsMapMediaSequence,
          },
      });
  }


  onRemoveMediaClick = (type, index) => {
      const { editingTransportPhotos, initialTransportMediaObject, city: { transportsMapMediaSequence } } = this.state;

      switch (type) {
          case 'editingCoverPhoto':
              this.setState({ city: { ...this.state.city, coverSequence: null } });
              break;
          case 'editingTransportPhotos':
              initialTransportMediaObject.splice(index, 1);
              transportsMapMediaSequence.splice(index, 1);
              editingTransportPhotos.splice(index, 1);
              this.setState({
                  city: { ...this.state.city, transportsMapMediaSequence },
                  editingTransportPhotos,
                  initialTransportMediaObject,
              });
              break;
          case 'editingExploredPhoto':
              this.setState({
                  city: { ...this.state.city, exploredPhotoSequence: null },
              });
              break;
          default:
      }
      if (type !== 'editingTransportPhotos') {
          this.setState({ [type]: true });
      }
  };

  getTrigger = () => {
      if (this.props.city) {
          return (
              <RowActionButton
                  text="Edit"
                  icon="pencil"
                  color="yellow"
                  onClick={this.handleOpen}
              />
          );
      }
      return (
          <Button
              icon
              primary
              style={{ marginBottom: 10 }}
              onClick={this.handleOpen}
              floated="right"
              labelPosition="left"
              size="small"
          >
              <Icon name="marker" /> Create City
          </Button>
      );
  };

  handleOpen = () => {
      const { city, token } = this.props;
      if (city) {
          const mediaRequests = [];
          if (city.coverSequence) {
              mediaRequests.push(getMedia(token, city.coverSequence));
          }
          if (city.exploredPhotoSequence) {
              mediaRequests.push(getMedia(token, city.exploredPhotoSequence));
          }

          const transports = ['bus', 'ferry', 'subway', 'train', 'tram'];

          transports.forEach((transport) => {
              if (city[`${transport}MapMediaSequence`]) {
                  mediaRequests.push(getMedia(token, city[`${transport}MapMediaSequence`]));
              } else mediaRequests.push(null);
              if (city[`${transport}MapPdfSequence`]) {
                  mediaRequests.push(getMedia(token, city[`${transport}MapPdfSequence`]));
              } else mediaRequests.push(null);
          });

          axios
              .all(mediaRequests)
              .then(axios.spread((cover, explored, busMedia, busPdf, ferryMedia, ferryPdf, subwayMedia, subwayPdf, trainMedia, trainPdf, tramMedia, tramPdf) => {
                  let coverData = null;
                  let exploredData = null;

                  const transportsMapMediaSequence = [];
                  const initialTransportMediaObject = [];
                  const editingTransportPhotos = [];

                  const transportResults = [
                      {
                          name: 'BUS',
                          media: busMedia ? busMedia.data : undefined,
                          pdf: busPdf ? busPdf.data : undefined,
                      },
                      {
                          name: 'FERRY',
                          media: ferryMedia ? ferryMedia.data : undefined,
                          pdf: ferryPdf ? ferryPdf.data : undefined,
                      },
                      {
                          name: 'SUBWAY',
                          media: subwayMedia ? subwayMedia.data : undefined,
                          pdf: subwayPdf ? subwayPdf.data : undefined,
                      },
                      {
                          name: 'TRAIN',
                          media: trainMedia ? trainMedia.data : undefined,
                          pdf: trainPdf ? trainPdf.data : undefined,
                      },
                      {
                          name: 'TRAM',
                          media: tramMedia ? tramMedia.data : undefined,
                          pdf: tramPdf ? tramPdf.data : undefined,
                      },
                  ];

                  if (cover) ({ data: coverData } = cover);
                  if (explored) ({ data: exploredData } = explored);

                  transportResults.forEach((transport) => {
                      if (transport.media) {
                          transportsMapMediaSequence.push({
                              name: transport.name,
                              media: city[`${transport.name.toLowerCase()}MapMediaSequence`],
                              pdf: false,
                              key: Math.random().toString().substr(2, 8),
                          });
                          initialTransportMediaObject.push(transport.media);
                          editingTransportPhotos.push(false);
                      } else if (transport.pdf) {
                          transportsMapMediaSequence.push({
                              name: transport.name,
                              media: city[`${transport.name.toLowerCase()}MapPdfSequence`],
                              pdf: true,
                              key: Math.random().toString().substr(2, 8),
                          });
                          initialTransportMediaObject.push(transport.pdf);
                          editingTransportPhotos.push(false);
                      }
                  });

                  this.setState({
                      ...INITIAL_STATE,
                      city: {
                          ...city,
                          transportsMapMediaSequence,
                      },
                      country: city.countryCode,
                      modalOpen: true,
                      initialCoverMediaObject: coverData,
                      initialExploredMediaObject: exploredData,
                      initialTransportMediaObject,
                      editingCoverPhoto: !coverData,
                      editingTransportPhotos,
                      editingExploredPhoto: !exploredData,

                  });
              }))
              .catch(() => {
                  displayNotification({
                      type: 'error',
                      message:
              'There was an error while fetching the saved media from this city.',
                  });
                  this.setState({
                      ...INITIAL_STATE,
                      city,
                      country: city.countryCode,
                      modalOpen: true,
                  });
              });
      } else {
          this.setState({ ...INITIAL_STATE, modalOpen: true });
      }
  };

  handleClose = () => this.setState(INITIAL_STATE);

  handleSubmit = () => {
      if (this.validateForm()) {
          const cityObject = { ...this.state.city };
          if (this.state.country !== cityObject.countryCode) {
              cityObject.countryCode = this.state.country;
          }
          if (this.props.city) {
              this.props.updateCity(
                  this.props.token,
                  {
                      ...cityObject,
                      geographicalArea: cityObject.geographicalArea.map(geo => ({ latitude: geo.latitude, longitude: geo.longitude })),
                  },
                  this.state.marker,
                  this.handleClose,
              );
          } else {
              this.props.createCity(
                  this.props.token,
                  cityObject,
                  this.state.marker,
                  this.handleClose,
              );
          }
      }
  };

  handleChange = (e, { name, value }) => {
      this.setState({
          city: {
              ...this.state.city,
              [name]: value,
          },
      });
  };

  handleComingSoon = () => {
      const { city } = this.state;

      this.setState({
          city: {
              ...city,
              comingSoon: !city.comingSoon,
          },
      });
  };

  handleChangeCityInformation = (html) => {
      this.setState({
          city: {
              ...this.state.city,
              cityInformation: html,
          },
      });
  };

  handlePlaceSelected = (place) => {
      const { mapRef } = this.state;
      const service = new window.google.maps.places.PlacesService(mapRef.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
      service.getDetails({ placeId: place.place_id }, (placeDetails, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              let countryCode = null;
              place.address_components.forEach((component) => {
                  if (component.types.includes('country')) {
                      countryCode = component.short_name;
                  }
              });
              mapRef.panTo({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
              });

              this.setState({
                  country: countryCode,
                  marker: {
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                  },
                  city: {
                      ...this.state.city,
                      name: placeDetails.name,
                      countryCode,
                      googleLocationId: place.place_id,
                      googlePlaceUrl: placeDetails.url,
                      latitude: place.geometry.location.lat(),
                      longitude: place.geometry.location.lng(),
                  },
              });
          } else {
              displayNotification({
                  type: 'error',
                  message: 'Unexpected error, please try again later.',
              });
          }
      });
  };

  validateForm = () => {
      let errors = {};
      Object.keys(this.state.city).forEach((field) => {
          switch (field) {
              case 'name':
              case 'coverSequence':
              case 'exploredPhotoSequence':
                  errors[field] = validateTextInputField(
                      this.state.city[field],
                      field,
                      displayFieldsMap[field],
                      'CREATE_CITY_FORM',
                  );
                  if (!errors[field]) delete errors[field];
                  break;
              default:
                  console.log(`Something went wrong evaluating ${field}.`);
          }
      });

      if (!this.state.country) {
          errors.country = validateTextInputField(
              this.state.country,
              'country',
              displayFieldsMap.country,
              'CREATE_CITY_FORM',
          );
      }

      Object.keys(errors).forEach((key) => {
          switch (key) {
              case 'coverSequence':
                  this.activeMediaSubmission.add(PHOTO_TYPES.COVER);
                  errors.mediaRaw = { message: errors.coverSequence };
                  break;
              case 'exploredPhotoSequence':
                  this.activeMediaSubmission.add(PHOTO_TYPES.EXPLORED);
                  errors.mediaRaw = { message: errors.exploredPhotoSequence };
                  break;
              default:
          }
      });
      if (
          !errors.name &&
      !errors.country &&
      ['coverSequence', 'exploredPhotoSequence'].some(e =>
          Object.keys(errors).includes(e))
      ) {
          displayNotification({
              type: 'error',
              message:
          'You haven\'t uploaded the mandatory media or you have not saved it yet.',
          });
      }
      if (Object.keys(errors).length === 0) errors = null;
      this.setState({ formErrors: errors });
      return errors === null;
  };

  validateMedia = (type, data, uploaderLocked) => {
      let errors = {};
      Object.keys(data).forEach((key) => {
          switch (key) {
              case 'mediaRaw':
                  if (!uploaderLocked) {
                      errors[key] = validateTextInputField(
                          data[key],
                          key,
                          displayFieldsMap[key],
                          'CREATE_CITY_FORM',
                      );
                      if (!errors[key]) delete errors[key];
                  }
                  break;
              default:
                  console.log(`Something went wrong evaluating ${key}.`);
          }
          if (key === 'mediaMeta') {
              Object.keys(data[key]).forEach((metaKey) => {
                  switch (metaKey) {
                      case 'captionDate':
                      case 'userObject':
                          errors[metaKey] = validateTextInputField(
                              data[key][metaKey],
                              metaKey,
                              displayFieldsMap[metaKey],
                              'CREATE_CITY_FORM',
                          );
                          if (!errors[metaKey]) delete errors[metaKey];
                          break;
                      default:
                          console.log(`Something went wrong evaluating ${metaKey}.`);
                  }
              });
          }
      });

      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
          errors = null;
          this.activeMediaSubmission.delete(type);
      } else this.activeMediaSubmission.add(type);
      this.setState({ formErrors: errors });
      return errors === null;
  };

  activeMediaSubmission = new Set();

  renderCoverPhotoUploader = () => {
      const { token, city } = this.props;
      const {
          formErrors,
          editingCoverPhoto,
          initialCoverMediaObject,
      } = this.state;
      const isEditing = !!city;

      return (
          <MediaUploader
              mediaOnly
              token={token}
              initialData={(isEditing && initialCoverMediaObject) || null}
              acceptedMimeTypes={[
                  'image/png',
                  'image/jpeg',
                  'image/svg+xml',
                  'video/mp4',
              ]}
              isEditing={editingCoverPhoto}
              formErrors={
                  this.activeMediaSubmission.has(PHOTO_TYPES.COVER) ? formErrors : null
              }
              onSavePress={(data, uploaderLocked) =>
                  this.onSavePhotoClick(PHOTO_TYPES.COVER, { mediaRaw: data }, uploaderLocked)
              }
              onEditMediaClick={() => this.onEditMediaClick('editingCoverPhoto')}
              onRemoveMediaClick={() => this.onRemoveMediaClick('editingCoverPhoto')}
              title="Welcome Screen Cover"
          />
      );
  };

  renderExploredUploader = () => {
      const { token, city } = this.props;
      const {
          formErrors,
          editingExploredPhoto,
          initialExploredMediaObject,
      } = this.state;
      const isEditing = !!city;

      return (
          <MediaUploader
              mediaOnly
              token={token}
              initialData={(isEditing && initialExploredMediaObject) || null}
              isEditing={editingExploredPhoto}
              formErrors={
                  this.activeMediaSubmission.has(PHOTO_TYPES.EXPLORED)
                      ? formErrors
                      : null
              }
              onSavePress={(data, uploaderLocked) =>
                  this.onSavePhotoClick(PHOTO_TYPES.EXPLORED, { mediaRaw: data }, uploaderLocked)
              }
              onEditMediaClick={() => this.onEditMediaClick('editingExploredPhoto')}
              onRemoveMediaClick={() =>
                  this.onRemoveMediaClick('editingExploredPhoto')
              }
              title="Explored Photo"
          />
      );
  };

  renderTransportUploader = () => {
      const { token, city } = this.props;
      const {
          editingTransportPhotos,
          initialTransportMediaObject,
          city: { transportsMapMediaSequence },
      } = this.state;
      const isEditing = !!city;

      const transports = [
          { text: 'Bus', value: 'BUS' },
          { text: 'Ferry', value: 'FERRY' },
          { text: 'Train', value: 'TRAIN' },
          { text: 'Tram', value: 'TRAM' },
          { text: 'Subway', value: 'SUBWAY' },
      ];

      const availableTransports = selected => ([
          ...(transports.filter(transport => !transportsMapMediaSequence.map(media => media.name).includes(transport.value))),
          (selected ? transports.find(transport => transport.value === selected) : {}),
      ]);

      return (
          <React.Fragment>
              {
                  transportsMapMediaSequence.map((transport, index) =>
                      (
                          <MediaUploader
                              key={transport.key}
                              mediaOnly
                              token={token}
                              options={availableTransports(transport.name)}
                              selectedOption={transport.name}
                              initialData={(isEditing && initialTransportMediaObject[index]) || null}
                              isEditing={editingTransportPhotos[index]}
                              onSavePress={(data, uploaderLocked) =>
                                  this.onSavePhotoClick(PHOTO_TYPES.TRANSPORTS, { mediaRaw: data }, uploaderLocked, index)
                              }
                              onOptionChange={optionValue => this.onChangeTransportMedia(index, optionValue, transport.media, transport.pdf, transport.key)}
                              onEditMediaClick={() => this.onEditMediaClick('editingTransportPhotos', index)}
                              onRemoveMediaClick={() => this.onRemoveMediaClick('editingTransportPhotos', index)}
                              title="Transport Map"
                              acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml', 'application/pdf']}
                          />
                      ))
              }
              {
                  transportsMapMediaSequence.length < transports.length && (
                      <Grid.Column width={16}>
                          <Button data-testid="add-new-transport-media" onClick={this.onAddTransportMedia} icon="plus" />
                      </Grid.Column>
                  )
              }
          </React.Fragment>
      );
  };

  renderCityInfo = () => {
      const {
          city: {
              emergencyNumber,
              policeDepartment,
              fireDepartment,
              taxis,
              tourismPhoneNumber,
              tourismEmail,
              cityInformation,
          },
      } = this.state;

      return (
          <React.Fragment>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="Emergency Number"
                          name="emergencyNumber"
                          value={emergencyNumber || ''}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="Police Department"
                          name="policeDepartment"
                          value={policeDepartment || ''}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="Fire Department"
                          name="fireDepartment"
                          value={fireDepartment || ''}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="Taxis"
                          name="taxis"
                          value={taxis || ''}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="Tourism Information"
                          name="tourismPhoneNumber"
                          value={tourismPhoneNumber || ''}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
                  <Grid.Column width={16}>
                      <Form.Input
                          fluid
                          label="&nbsp;"
                          name="tourismEmail"
                          value={tourismEmail || ''}
                          placeholder="Email"
                          onChange={this.handleChange}
                      />
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16} className="field">
                      <div className="field">
                          <label>City Information</label>
                          <EditorArea
                              boldYellow
                              onChange={this.handleChangeCityInformation}
                              initialContent={cityInformation || ''}
                          />
                      </div>
                  </Grid.Column>
              </Grid.Row>
          </React.Fragment>
      );
  }

  render() {
      const { modalOpen, city, formErrors } = this.state;
      const { name, geographicalArea, comingSoon } = city;
      const isEditing = !!this.props.city;

      if (!modalOpen) return this.getTrigger();

      return (
          <Modal
              closeIcon
              data-testid="create-city-modal"
              className="create-city-modal"
              onClose={this.handleClose}
              onUnmount={this.props.fixBody}
              closeOnDimmerClick={false}
              closeOnEscape={false}
              open={modalOpen}
              size="large"
              trigger={this.getTrigger()}
          >
              <Modal.Header>
                  {isEditing ? `Edit City - ${this.props.city.name}` : 'Create City'}
              </Modal.Header>
              <Modal.Content>
                  <Header as="h4">Landing Point</Header>
                  <Grid>
                      <Grid.Row>
                          <Grid.Column width={8}>
                              <Form.Input
                                  fluid
                                  name="place"
                                  label={`Place ${
                                      (formErrors &&
                      formErrors.name &&
                      formErrors.name.message) ||
                    ''
                                  }`}
                                  error={formErrors && !!formErrors.name}
                                  input={
                                      <Autocomplete
                                          className="google-autocomplete"
                                          defaultValue={name}
                                          onPlaceSelected={this.handlePlaceSelected}
                                          autoComplete="new-password"
                                          name={`place${Date.now()}`}
                                          types={['(cities)']}
                                      />
                                  }
                              />
                          </Grid.Column>
                          <Grid.Column width={8}>
                              <div
                                  className={`field ${
                                      formErrors && formErrors.country ? 'error' : ''
                                  }`}
                              >
                                  <label>{`Country ${
                                      (formErrors &&
                      formErrors.country &&
                      formErrors.country.message) ||
                    ''
                                  }`}
                                  </label>
                                  <Dropdown
                                      fluid
                                      selection
                                      error={formErrors && !!formErrors.country}
                                      onChange={this.onCountryChange}
                                      value={this.state.country}
                                      placeholder="Select Country"
                                      options={getCountriesList()}
                                  />
                              </div>
                          </Grid.Column>
                      </Grid.Row>
                      <Checkbox
                          label="Coming Soon"
                          name="comingSoon"
                          className="coming-soon-checkbox"
                          checked={comingSoon}
                          onChange={this.handleComingSoon}
                      />
                      <Grid.Column width={16}>
                          <MapElement
                              isMarkerShown
                              markerPosition={this.state.marker}
                              onMapMounted={this.onMapMounted}
                              onMarkerDragEnd={this.onMarkerDragEnd}
                          />
                      </Grid.Column>
                      <Header as="h4">Geographic Area</Header>
                      <Grid.Column width={16}>
                          <PolygonMapElement
                              setGeographicalArea={area => this.handleChange({}, { name: 'geographicalArea', value: area })}
                              geographicalArea={geographicalArea}
                              centerPosition={this.state.marker || undefined}
                          />
                      </Grid.Column>
                      {this.renderCoverPhotoUploader()}
                      {this.renderExploredUploader()}
                      {this.renderTransportUploader()}
                      {this.renderCityInfo()}
                  </Grid>
              </Modal.Content>
              <Modal.Actions>
                  <Button
                      icon
                      color="green"
                      labelPosition="right"
                      onClick={this.handleSubmit}
                  >
                      Save <Icon name="save" />
                  </Button>
              </Modal.Actions>
          </Modal>
      );
  }
}

CreateCityModal.propTypes = {
    city: PropTypes.object,
    token: PropTypes.string,
    createCity: PropTypes.func,
    updateCity: PropTypes.func,
    createMedia: PropTypes.func,
    updateMedia: PropTypes.func,
};

CreateCityModal.defaultProps = {
    city: null,
    token: null,
    createCity: () => {},
    updateCity: () => {},
    createMedia: () => {},
    updateMedia: () => {},
};

const mapStateToProps = ({ auth }) => {
    const { token } = auth;
    return { token };
};

export default connect(mapStateToProps, {
    createCity,
    updateCity,
    createMedia,
    updateMedia,
})(withModal(CreateCityModal));
